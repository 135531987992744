import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProdutoPizza, ProdutoMeioameioModel } from '@mobile/models/produtos/produto.model';

@Component({
  selector: 'app-item-cardapio-meioameio',
  templateUrl: './item-cardapio-meioameio.component.html',
  styleUrls: ['./item-cardapio-meioameio.component.scss'],
})
export class ItemCardapioMeioameioComponent implements OnInit {

  @Input() type: 'salgadas' | 'salgadas2' | 'doces' | 'bebidas'
  @Input() broto: boolean = false;
  @Input() item: ProdutoMeioameioModel;
  @Input() user: any;
  @Input() trem: boolean = false;
  @Input() biTrem: boolean = false;

  produtoMeioameio: ProdutoMeioameioModel;
  @Output('removeItemMeioameio') removeItemMeioameio = new EventEmitter<IProdutoPizza>();

  isPromo = false;
  constructor() { }

  ngOnInit() {
    if (this.item instanceof ProdutoMeioameioModel) {
      this.produtoMeioameio = this.item;  ''
    }
    this.loadPromo();
  }

  loadPromo() {
    const itensPromo = (this.produtoMeioameio instanceof ProdutoMeioameioModel && this.item.getParts() as IProdutoPizza[] || []).every(item => item.promo)
    this.isPromo = itensPromo && this.user?.pontos_fidelidade > 10
  }

  remover(item: IProdutoPizza) {
    this.removeItemMeioameio.emit(item);
  }


}
