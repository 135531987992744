import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class FcmService {
  private _token: string;
  constructor(
    private router: Router,
    private toastCtrl: ToastController
  ) { }

  initPush() {
    if (Capacitor.isNativePlatform()) {
      this.registerPush();
    }
  }

  getToken() {
    return this._token || null;
  }

  async getMessages() {
    if (Capacitor.isNativePlatform()) {
      return await PushNotifications.getDeliveredNotifications();
    }
    return null;
  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: Token) => {
        this._token = token.value;
        console.log('FcmService PushNotifications token', JSON.stringify(token));
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        this.toast(notification);
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {

        this.router.navigateByUrl(`/status/`);
        console.log('Action performed: ' + JSON.stringify(notification.notification));

        // const data = notification.notification.data;
        //   if (data.detailsId) {
        //     this.router.navigateByUrl(`/home/${data.detailsId}`);
        //   }

      }
    );
  }

  private toast(data: PushNotificationSchema) {
    this.toastCtrl.create({
      header: data.title,
      message: data.body,
      duration: 2000,
      position: "bottom",
      color: "danger"
    }).then((toast) => toast.present());
  }
}
