// import { PixType } from '@vtr/pix';

export const environment: any = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBNG0hbNULIHQ0oSUhHKDT0t1_fTb9WXD0',
    authDomain: 'pizzaria-68f26.firebaseapp.com',
    projectId: 'pizzaria-68f26',
    storageBucket: 'pizzaria-68f26.appspot.com',
    messagingSenderId: '442075768410',
    appId: '1:442075768410:web:7322637ff910718f845a63'
  },
  pix: {
    default: 'stone',
    pagseguro: 'https://secure.sandbox.api.pagseguro.com/instant-payments/cob',
    stone: {
      API: 'https://sandbox-api.openbank.stone.com.br/api/v1/pix',
      AUTH: 'https://sandbox-accounts.openbank.stone.com.br/auth/realms/stone_bank/protocol/openid-connect/token'
    }
  }
};


