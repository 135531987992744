import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { PedidoService } from '@mobile/app/services/pedido/pedido.service';
import { FirebaseProvider } from 'src/providers/firebase';

@Component({
  selector: 'app-status',
  templateUrl: './status.page.html',
  styleUrls: ['./status.page.scss'],
})
export class StatusPage implements OnInit {
  pedidos: any = [];
  id: any = [];
  loading: true;
  constructor(
    private modalCtrl: ModalController,
    private fb: FirebaseProvider,
    private storage: Storage,
    private router: Router,
    private alertCtrl: AlertController,
    private pedidoService: PedidoService,
  ) { }

  async ngOnInit() {
    // this.id = await this.storage.get('pedidoEfetuado');
    // if (this.id) {
    //   this.atualizarPedido(this.id);
    // }

    this.pedidoService
      .getPedidoEfetuado()
      .subscribe(pedidoEfetuado => {
        this.id = pedidoEfetuado || [];
        this.atualizarPedido(this.id);
      });
  }

  doRefresh(event) {
    setTimeout(() => {
      this.atualizarPedido(this.id).then(() => event.target.complete());
    }, 500);
  }

  async atualizarPedido(id) {
    let pedidoAberto = id.length;
    let index = id.length - 1;
    id.forEach(async ped => {
      let pedido = await this.fb.getPedido(ped);
      if (pedido['status'] > 1 && pedido['pagamento'] == 'pontos') {
        this.storage.remove('pedidoPontos');
      }

      this.pedidos[index] = pedido;
      if (pedido['status'] >= 4) {
        pedidoAberto--;
        if (pedidoAberto == 0) {
          this.storage.remove('pedidoEfetuado');
          this.storage.remove('pedidoPontos');
          this.closeModal();
        }
      }
      index--;
    });
  }

  async presentHelpAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Ajuda',
      subHeader: "Alterações ou cancelamentos devem ser feitos com o restaurante. (13) 3024-2126",
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    });
    await alert.present();

  }

  goToHome() {
    this.router.navigateByUrl('/tabs/home');
    // this.closeModal();
  }
  async closeModal() {
    try {
      await this.modalCtrl.dismiss();
    }
    catch (err) {
      this.router.navigateByUrl('/tabs/home');
    }
  }
}
