import * as lodash from 'lodash';
export default class Utils {
  static normalizeText = text => text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
  static priceBroto = price => Utils.casasDecimais(price * 0.7, 2);
  static calculoPrice = (price, isBroto = false) => isBroto ? Utils.priceBroto(price) : price;

  /**
   * Retorna um ID dinamico
   * @returns number
   */
  static dinamicID = () => (Date.now() + Math.random()).toString().replace('.', '');

  static calcDezPorcentoSubtotal = (subtotal: number): number => Utils.casasDecimais((subtotal * 0.10), 2);
  static calcTaxaEntrega = (subtotal: number, taxa_bairro: number) => {
    return Math.max(taxa_bairro, Utils.calcDezPorcentoSubtotal(subtotal));
  }

  /**
   * casasDecimais(5.12345) retorna 5.12
   * @param num Numero para fixar as casas
   * @param precisao Numero de casas fixas
   * @returns Retorno
   */
  static casasDecimais(num, precisao) {
    const casas = Math.pow(10, precisao);
    return Math.floor(num * casas) / casas;
  }

  static median(...array: number[]) {
    array.sort((a, b) => a - b);
    const mid = array.length / 2;
    return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
  }

  /**
   * Pega o Preco do Adicional(Adicional e Borda)
   * @param borda
   * @param isBroto
   */
  static getPriceAdicional = (borda, isBroto = false) => {

    if (isBroto) {
      // Calcula o preço cheio dos Adicionais
      // return borda ? borda.price_broto : 0;
    }

    return borda ? borda.price : 0;
  }
}


export const groupBy = (key, args) => {
  return lodash<any>(args)
    .groupBy(key)
    .map((itens, key) => {
      return ({
        key: key && key != "null" && key != "undefined" ? key : "Balcão",
        collection: itens ? lodash.map(itens, item => item) : [],
        size: (itens ? itens : []).length,
        total_entregas: (itens ? itens : []).reduce((total, i) => total + (i.taxa_entrega ? i.taxa_entrega : 0) , 0)
      });
    })
    .value();
};

export const orderBy = (key: string[], order: ('asc'|'desc')[], args) => {
  return lodash.orderBy(args, key, order);
};
