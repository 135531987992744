import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { PedidoService } from '@mobile/app/services/pedido/pedido.service';
import { SacolaPage } from 'src/app/sacola/sacola.page';

@Component({
  selector: 'app-footer-sacola',
  templateUrl: './footer-sacola.component.html',
  styleUrls: ['./footer-sacola.component.scss'],
})
export class FooterSacolaComponent implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    private storage: Storage,
    private pedidoService: PedidoService,
  ) { }
  pedido: any = false;

  async ngOnInit() {

    this.pedidoService.getPedido().subscribe(data => {
      this.pedido = data;
    })

  }

  async getPedido() {
    let pedidoAtual = await this.storage.get('pedido');
    return pedidoAtual && pedidoAtual.length > 0 ? pedidoAtual : false;
  }

  async openSacolaModal() {
    const modal = await this.modalCtrl.create({
      component: SacolaPage,
      swipeToClose: true,
      cssClass: "modal",
    });
    return await modal.present();
  }

}
