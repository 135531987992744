import { IProdutoPizza, PriceType, ProdutoMeioameioModel } from '@mobile/models/produtos/produto.model';

import { configuation } from '../app.configuration';
import { TotalPartsEnum } from '../enuns/total-parts.enum';

export class PriceHelper {

  /**
   * Retora o Valor da meio-a-meio baseado na configuaração 'maior' ou 'metade' do valor
   * @param itens
   * @returns
   */
  static meioameioPrice(
    itens: (IProdutoPizza | { price: number; price_broto: number })[]
  ): number {
    switch (configuation.pizza_meioamio_payment) {
      case "maior":
        const prices: number[] = itens.map((item) => item.price);
        return Math.max(...prices);

      case "metade":
        return itens.reduce(
          (valor, item) => valor + item.price / itens.length,
          0
        );
    }
  }

  /**
   * Retora o Valor da meio-a-meio baseado na configuaração 'maior' ou 'metade' do valor
   * @param itens
   * @returns
   */
  static meioameioPriceBroto(
    itens: (IProdutoPizza | { price: number; price_broto: number })[]
  ): number {
    switch (configuation.pizza_meioamio_payment) {
      case "maior":
        const prices: number[] = itens.map((item) => item.price_broto);
        return Math.max(...prices);

      case "metade":
        return itens.reduce(
          (valor, item) => valor + item.price_broto / itens.length,
          0
        );
    }
  }

  /**
   * Retora o Valor da Pizza Trem baseado na configuaração 'maior' ou 'metade' do valor
   * @param itens
   * @returns
   */
  static tremPrice(
    itens: (
      | IProdutoPizza
      | { price: number; price_broto: number; price_trem: number }
    )[]
  ): number {
    switch (configuation.pizza_meioamio_payment) {
      case "maior":
        const prices: number[] = itens.map((item) => item.price_trem);
        return Math.max(...prices);

      case "metade":
        return itens.reduce(
          (valor, item) => valor + item.price_trem / itens.length,
          0
        );
    }
  }

  /**
   * Retora o Valor da Pizza Trem baseado na configuaração 'maior' ou 'metade' do valor
   * @param itens
   * @returns
   */
  static biTremPrice(
    itens: (
      | IProdutoPizza
      | {
          price: number;
          price_broto: number;
          price_trem: number;
          price_bi_trem: number;
        }
    )[]
  ): number {
    switch (configuation.pizza_meioamio_payment) {
      case "maior":
        const prices: number[] = itens.map((item) => item.price_bi_trem);
        return Math.max(...prices);

      case "metade":
        return itens.reduce(
          (valor, item) => valor + item.price_bi_trem / itens.length,
          0
        );
    }
  }

  static getPrice(
    key: PriceType,
    itens: (
      | IProdutoPizza
      | {
          price: number;
          price_broto: number;
          price_trem: number;
          price_bi_trem: number;
        }
    )[]
  ): number {
    switch (configuation.pizza_meioamio_payment) {
      case "maior":
        const prices: number[] = itens.map((item) => item[key]);
        return Math.max(...prices);

      case "metade":
        return itens.reduce(
          (valor, item) => valor + item[key] / itens.length,
          0
        );
    }
  }

  static getPriceType(model: ProdutoMeioameioModel): PriceType {

    const qtd_parts = model!.getParts()?.length ?? 0;
    switch(qtd_parts) {
      case TotalPartsEnum.BI_TREM: return 'price_bi_trem';
      case TotalPartsEnum.TREM: return 'price_trem';
      default:
        if (model.broto) return 'price_broto';
        return 'price';
    }

  }
}
