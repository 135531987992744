import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

import { EnderecosPage } from "../enderecos/enderecos.page";
import { SacolaPage } from "../sacola/sacola.page";
import { StatusPage } from "../status/status.page";
import { FooterSacolaComponent } from "./footer/footer-sacola/footer-sacola.component";
import { FooterStatusComponent } from "./footer/footer-status/footer-status.component";
import { ItemCardapioMeioameioComponent } from "./item-cardapio-meioameio/item-cardapio-meioameio.component";
import { ItemCardapioComponent } from "./item-cardapio/item-cardapio.component";
import { LojaFechadaComponent } from "./loja-fechada/loja-fechada.component";
import { PipesModule } from "../pipes/pipes.module";
import { SearchTextComponent } from "./search-text/search-text.component";

@NgModule({
  declarations: [
    LojaFechadaComponent,
    FooterSacolaComponent,
    FooterStatusComponent,
    ItemCardapioComponent,
    ItemCardapioMeioameioComponent,
    SacolaPage,
    EnderecosPage,
    StatusPage,
    SearchTextComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  exports: [
    LojaFechadaComponent,
    FooterSacolaComponent,
    FooterStatusComponent,
    ItemCardapioComponent,
    ItemCardapioMeioameioComponent,
    SearchTextComponent,
  ],
})
export class ComponentsModule {}
