import Utils from "@mobile/helpers/utils";
import { TipoTaxaEntrega } from "./tipo-taxa-entrega.type";



/**
 * Classe para calcular a taxa de entrega
 * @author Vitor Pereira<vitor_gja_@hotmail.com>.
 */
export class TaxaEntrega {
  private _entrega = false;
  private _taxa_gratis = 0;
  private _taxa_bairro = 0;

  constructor(entrega, taxa_gratis, taxa_bairro) {
    this._entrega = entrega;
    this._taxa_gratis = taxa_gratis;
    this._taxa_bairro = taxa_bairro;
  }

  /**
   * Calcula a taxa de Entrega baseado na taxa por bairro, taxa por 10 porcento e entrega gratis
   * @param subtotal
   * @returns
   */
  calcTaxaEntrega(subtotal: number): number {
    if (this._entrega) {
      return Utils.calcTaxaEntrega(subtotal, this._taxa_bairro);
    } else {
      return this._taxa_gratis;
    }
  }

  tipoTaxaEntrega(subtotal: number): TipoTaxaEntrega {
    if (this._entrega) {
      return (this._taxa_bairro > Utils.calcDezPorcentoSubtotal(subtotal))
        ? 'bairro'
        : '10porcento';
    } else {
      return 'gratis';
    }
  }

  get entrega() {
    return this._entrega;
  }
  set entrega(entrega: boolean) {
    this._entrega = entrega;
  }
  get taxa_bairro() {
    return this._taxa_bairro;
  }
  set taxa_bairro(taxa_bairro: number) {
    this._taxa_bairro = taxa_bairro;
  }

}
