import { Component, OnInit } from '@angular/core';
import { FirebaseProvider } from '@mobile/providers/firebase';

@Component({
  selector: "app-loja-fechada",
  templateUrl: "./loja-fechada.component.html",
  styleUrls: ["./loja-fechada.component.scss"],
})
export class LojaFechadaComponent implements OnInit {
  lojaFechada$ = this.firebaseProvider.getLojaFechada();

  constructor(private firebaseProvider: FirebaseProvider) {}

  ngOnInit() {}
}
