/**
 * Enum para Status dos Pedidos
 */
export enum StatusPedidoEnum {

  /**
   * Pedido enviado
   */
  ENVIADO = 1,

  /**
   * Em preparo
   */
  PREPARO = 2,

  /**
   * Saiu para entrega / Retirar na Loja
   */
  SAIU_PARA_ENTREGA = 3,

  /**
   * Pedido entregue
   */
  ENTREGUE = 4,

  /**
   * Pedido cancelado
   */
  CANCELADO = 5,

  /**
   * Retirar na Loja
   */
  RETIRAR_NA_LOJA = 6,

  /**
   * Aguardando Pagamento
   */
  AGUARDANDO_PAGAMENTO = 7

}
