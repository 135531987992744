import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-item-cardapio',
  templateUrl: './item-cardapio.component.html',
  styleUrls: ['./item-cardapio.component.scss']
})
export class ItemCardapioComponent implements OnInit {

  @Input() type: 'salgadas' | 'salgadas2' | 'doces' | 'bebidas'
  @Input() broto: boolean = false;
  @Input() trem: boolean = false;
  @Input() biTrem: boolean = false;
  @Input() item: any;
  @Input() user: any;
  @Output('addProduto') addPizza$ = new EventEmitter(null);

  constructor() { }

  ngOnInit() {
  }

  click = 0
  addProduto(item) {
    this.addPizza$.emit(item);

  }

}
