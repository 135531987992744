import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

interface FirebaseNotificationInterface {
  fcmMessageId: "b2b64178-e807-4f1c-a54b-9b4319a725cd";
  from: "442075768410";
  notification: NotificationInterface
  data: any;
  priority: "normal";
}
interface NotificationInterface {
  title: "Seu pedido está a caminho!",
  body: "Tobias está levando até você.",
  icon: "fcm_push_icon"
}

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  token = null;

  constructor(
    private afMessaging: AngularFireMessaging,
    private toastCtrl: ToastController,
  ) {
    this.requestPermission();
  }

  async requestPermission() {

    const token = await this.getToken().toPromise();
    console.log('MessagingService token', token);

    if (!token) {

      const toast = await this.toastCtrl.create({
        message: 'Aceita receber notificações de Like Pizzas?',
        buttons: [
          {
            text: 'Aceitar',
            role: 'success',
            handler: () => {
              console.log('requestPermission aceitar');
              this.afMessaging.requestToken.pipe(
                tap(
                  // tslint:disable-next-line: no-shadowed-variable
                  token => console.log('Store token to server: ', token),
                  err => console.warn('Store token error: ', err)
                ),
              ).subscribe(data => console.log('Store data subscribe : ', data),);
            },
          }
        ]
      });
      toast.present();
    }

  }

  getMessages(): Observable<FirebaseNotificationInterface> {
    return this.afMessaging.messages as Observable<FirebaseNotificationInterface>;
  }

  async deleteToken() {
    const token = await this.getToken().toPromise();
    console.log('deleteToken', token);
    if (token) {
      this.afMessaging.deleteToken(token);
      this.token = null;
    }
  }

  getToken(): Observable<string> {
    return this.afMessaging.getToken;
  }
}
