import { Component } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';

import { FcmService } from './services/fcm.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private fcmService: FcmService,
    private toastCtrl: ToastController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // Trigger the push setup
      this.fcmService.initPush();
      this.subscribePush();
    });
  }

  async subscribePush() {
    const messages = await this.fcmService.getMessages();

    if (messages && messages.notifications) {
      console.log("subscribePush data",  messages.notifications);
      messages.notifications.forEach(data => {

        this.toastCtrl.create({
          header: data.title,
          message: data.body,
          duration: 2000,
          position: "bottom",
          color: "danger"
        }).then((toast) => toast.present());
      });

    }

  }

}
