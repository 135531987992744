import { Injectable } from '@angular/core';
import { AlertController, AlertOptions } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { IPedido } from '@mobile/models/pedido/pedido.model';
import { StatusPedidoEnum } from '@mobile/models/status-pedido.enum';
import { FirebaseProvider } from '@mobile/providers/firebase';
import { BehaviorSubject, Unsubscribable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  private _pedido = new BehaviorSubject(null);
  private _pedidoEfetuado = new BehaviorSubject<string[]>([]);
  private _pedidosNotificados = new BehaviorSubject<{idPedido: string, statusNotificados}[]>([]);

  private _watchPedidos: { [key: string]: Unsubscribable } = {};

  constructor(
    private alertCtrl: AlertController,
    private storage: Storage,
    private firebaseProvider: FirebaseProvider,
  ) {
    this.ngOnInit();
  }

  async ngOnInit(): Promise<void> {
    const pedido = await this.storage.get('pedido');
    const pedidoEfetuado = await this.storage.get('pedidoEfetuado');
    const pedidosNotificados = await this.storage.get('pedidosNotificados') ?? [];

    this._pedido.next(pedido);
    this._pedidoEfetuado.next(pedidoEfetuado);
    this._pedidosNotificados.next(pedidosNotificados);


    this.getPedidoEfetuado().subscribe((pedidoEfetuado: string[]) => {

      (pedidoEfetuado || [])
        .filter(key => Object.keys(this._watchPedidos).indexOf(key))
        .forEach(idPedido => {
          this._watchPedidos[idPedido] = this.firebaseProvider.watchPedido(idPedido).subscribe(
            (data: IPedido) => {

             const pedidosNotificados = this._pedidosNotificados.value;
             const statusNaoNotificado = pedidosNotificados.some(n => n.idPedido === data.id && (n.statusNotificados ?? []).indexOf(data.status) >= 0 )
              if(statusNaoNotificado) {
                this.changePedido(data);
              }

              if (data.status == StatusPedidoEnum.ENTREGUE || data.status == StatusPedidoEnum.CANCELADO) {
                this._watchPedidos[data.id] && this._watchPedidos[data.id].unsubscribe();

                let pedidos = this._pedidoEfetuado.value;
                pedidos = pedidos.filter(p => p !== data.id);
                this.setPedidoEfetuado(pedidos);
              }
            }
          );
        })


    })





  }

  async changePedido(pedido: IPedido) {

    const opts: AlertOptions = {
      header: `Pedido #${pedido.id}`,
      buttons: ['ok']
    };

    switch (pedido.status) {
      case StatusPedidoEnum.PREPARO:
        opts.message = 'Seu pedido foi aprovado e esta em Preparo!';
        break;
      case StatusPedidoEnum.SAIU_PARA_ENTREGA:
        opts.message = `O Motoboy ${pedido.entregador} já está levando seu pedido!`;
        break;
      case StatusPedidoEnum.ENTREGUE:
        opts.message = `Seu pedido foi entregue com sucesso!`;
        break;
      case StatusPedidoEnum.CANCELADO:
        opts.message = `Seu pedido foi cancelado, para mais informações entre em contato!`;
        break;

      case StatusPedidoEnum.RETIRAR_NA_LOJA:
        opts.message = 'Seu pedido já pode ser retirado na loja!';
        break;

      default:
        break;
    }

    if (opts.message) {
      const alert = await this.alertCtrl.create(opts);
      alert.present();
      await this.updatePedidosNotificados(pedido);
    }
  }

  setPedido(pedido) {
    this.storage.set('pedido', pedido);
    this._pedido.next(pedido);
  }

  getPedido() {
    return this._pedido.asObservable();
  }

  removePedido() {
    this.storage.remove('pedido');
    this._pedido.next(null);
  }

  setPedidoEfetuado(pedido) {
    this.storage.set('pedidoEfetuado', pedido);
    this._pedidoEfetuado.next(pedido);
  }

  getPedidoEfetuado() {
    return this._pedidoEfetuado.asObservable();
  }

  removePedidoEfetuado() {
    this.storage.remove('pedidoEfetuado');
    this._pedidoEfetuado.next([]);
  }

  set pedidosNotificados(pedidos: { idPedido: string; statusNotificados: any; }[]) {
    this.storage.set('pedidosNotificados', pedidos);
    this._pedidosNotificados.next(pedidos);
  }

  async setNovoPedidosNotificados(pedido: { idPedido: string; statusNotificados: any; }) {
    const pedidosNotificados: { idPedido: string; statusNotificados: any; }[] = (await this.storage.get('pedidosNotificados')) ?? [];
    pedidosNotificados.push(pedido);
    this.pedidosNotificados = pedidosNotificados;
  }

  async updatePedidosNotificados(pedido: { id: string; status: any; }) {
    const pedidosNotificados: { idPedido: string; statusNotificados: any; }[] = (await this.storage.get('pedidosNotificados')) ?? [];
    const pedigoNotificado = pedidosNotificados.find(notificados => notificados.idPedido = pedido.id);

    // console.log('updatePedidosNotificados pedigoNotificado', pedigoNotificado)

    if (pedigoNotificado) {
      pedigoNotificado.statusNotificados.push(pedido.status);
      this.pedidosNotificados = pedidosNotificados;
    } else {
      this.setNovoPedidosNotificados({
        idPedido: pedido.id,
        statusNotificados: pedido.status,
      });
    }

  }
}
