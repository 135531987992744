import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './services/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/tabs/home', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./autentication/login.module').then( m => m.LoginPageModule)
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'menu',
  //   loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'cardapio/:id',
  //   loadChildren: () => import('./cardapio/cardapio.module').then( m => m.CardapioPageModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'sacola',
  //   loadChildren: () => import('./sacola/sacola.module').then( m => m.SacolaPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'detalhes-item',
    loadChildren: () => import('./detalhes-item/detalhes-item.module').then( m => m.DetalhesItemPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'menu',
  //   loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'menu-cardapio',
    loadChildren: () => import('./menu-cardapio/menu-cardapio.module').then( m => m.MenuCardapioPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'enderecos',
    loadChildren: () => import('./enderecos/enderecos.module').then( m => m.EnderecosPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'status',
  //   loadChildren: () => import('./status/status.module').then( m => m.StatusPageModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'user',
  //   loadChildren: () => import('./user/user.module').then( m => m.UserPageModule)
  // },
  {
    path: 'politica-de-privacidade',
    loadChildren: () => import('./politica-de-privacidade/politica-de-privacidade.module').then( m => m.PoliticaDePrivacidadePageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule),
    // canActivate: [AuthGuard]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
