import { FirebaseProvider } from './firebase';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Storage } from '@ionic/storage';

@Injectable()
export class AuthProvider{

    constructor(
      private storage: Storage,
      private afAuth: AngularFireAuth,
      private firebaseProvider: FirebaseProvider) { }

    // Create user
    register = (data: {email: string, password: string}) => this.afAuth.createUserWithEmailAndPassword(data.email, data.password);

    // Login user
    login = async (data: {email: string, password: string}) => {
        await this.afAuth.setPersistence(auth.Auth.Persistence.LOCAL);
        return this.afAuth.signInWithEmailAndPassword(data.email, data.password);
    }

    resetPassword(email) {
      return this.afAuth.sendPasswordResetEmail(email);
    }

    async updateEmail(newEmail) {
      let user = await this.storage.get('user');
      let userAtt = await this.firebaseProvider.getUser(user['uid']);
      const currentUserFirestore = userAtt.data();
      currentUserFirestore.email = newEmail;

      await this.firebaseProvider.getUser(user['uid'])

      const currentUser = await this.afAuth.currentUser;
      currentUser.updateEmail(newEmail);

      return this.afAuth.updateCurrentUser(currentUser)
    }

    async updateCurrentUser({user, resetPassword}) {
      const currentUser = await this.afAuth.currentUser;
      if (resetPassword) {
        currentUser.updatePassword(resetPassword.newpassword);
      }
      currentUser.updateEmail(user.email);
      // currentUser.updatePhoneNumber(`+55${user.tel}`);
      currentUser.updateProfile({
        displayName: user.name
      });

      await this.afAuth.updateCurrentUser(currentUser);

      let userStorage = await this.storage.get('user');
      let userAtt = await this.firebaseProvider.getUser(userStorage['uid']);
      let userData = userAtt.data();
      userData.name = user.name,
      userData.tel = user.tel,
      userData.email = user.email,
      await this.firebaseProvider.postUser(userData);
    }
}
